<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.ledger_type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">微信分账</el-radio-button>
        <el-radio-button :label="2">易票联分账</el-radio-button>
      </el-radio-group>
    </div>

    <div class="filter-container">
      <el-button class="filter-item" type="primary" size="small" @click="handleCreate">添加</el-button>

      <el-input v-model="listQuery.role_name" placeholder="分账方名称" size="small"  class="filter-item" style="width: 220px;" clearable/>

      <el-select v-model="listQuery.is_use" placeholder="是否使用" size="small" class="filter-item" style="width: 150px" clearable>
        <el-option label="否" :value="0" />
        <el-option label="是" :value="1" />
      </el-select>

      <el-select v-model="listQuery.is_balance_strike" placeholder="是否结余再分账" size="small" class="filter-item" style="width: 150px" clearable>
        <el-option label="否" :value="0" />
        <el-option label="是" :value="1" />
      </el-select>

      <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
<!--          {{ scope.$index + 1 }}-->
           {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="分账类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.ledger_type === 1">微信分账</el-tag>
          <el-tag type="warning" v-else-if="scope.row.ledger_type === 2">易票联分账</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="分账方名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.role_name }}
        </template>
      </el-table-column>

      <el-table-column label="金额" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.money }}元 </template>
      </el-table-column>

      <el-table-column label="比例" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.proportion }}% </template>
      </el-table-column>

      <el-table-column label="收款方式" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.pay_method === 'PERSONAL_OPENID'">零钱</div>
          <div v-else-if="scope.row.pay_method === 'MERCHANT_ID'">商户号</div>
        </template>
      </el-table-column>

      <el-table-column label="微信收款账号" min-width="110" align="center" v-if="listQuery.ledger_type === 1">
        <template slot-scope="scope">
          {{ scope.row.account }}
        </template>
      </el-table-column>

      <el-table-column label="易票联商户名称" min-width="110" align="center" v-if="listQuery.ledger_type === 2">
        <template slot-scope="scope">
          {{ scope.row.merchant ? scope.row.merchant.merchant_name: '' }}
        </template>
      </el-table-column>

      <el-table-column label="是否使用" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_use">是</el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="是否结余再分账" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_balance_strike">是</el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="优先级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>

      <el-table-column label="打款openid" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.openid }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.contact" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="success"
            size="mini"
            v-if="scope.row.is_use === 0"
            @click="handleUse(scope.row)"
            >使用</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-else
            @click="handleUse(scope.row)"
            >禁用</el-button
          >
          <el-button type="danger" size="mini" @click="handleDel(scope.row)"
          >删除</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="handleToPhones(scope.row)"
            >手机号列表</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="revenueLog(scope.row)"
            >分账收入记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-position="right" label-width="150px">
        <div style="font-size:12px;color:red;margin-bottom:20px;">*注意：分账方名称为【配送】的账户将按照订单配送费进行分账,不再按此页面【收款额度】计算</div>

        <el-form-item label="分账类型" prop="ledger_type">
          <el-radio-group v-model="form.ledger_type" :disabled="true">
            <el-radio :label="1">微信分账</el-radio>
            <el-radio :label="2">易票联分账</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="分账方名称" prop="role_name">
          <el-input v-model="form.role_name" placeholder="请输入分账方名称" />
        </el-form-item>

        <el-form-item label="收款额度">
          <div class="input">
            <el-form-item label="每单金额(元)" prop="money" label-width="110px">
              <el-input-number v-model="form.money" :min="0" :precision="2"></el-input-number>
            </el-form-item>

            <el-form-item label="每单比例(%)" prop="proportion" label-width="140px">
              <el-input-number v-model="form.proportion" :min="0" :max="100" :precision="2"></el-input-number>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="收款方式" prop="pay_method">
          <el-radio-group v-model="form.pay_method" :disabled="!!form.id">
            <el-radio label="MERCHANT_ID">商户号</el-radio>
            <el-radio label="PERSONAL_OPENID" :disabled="form.ledger_type === 2">零钱</el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="form.ledger_type === 1">
          <el-form-item label="收款账号" prop="account">
            <el-input v-model="form.account" :placeholder="form.pay_method === 'MERCHANT_ID' ? '请输入商户号' : '请输入用户openid'" :disabled="!!form.id" />
            <div style="font-size:12px;color:red;" v-if="form.pay_method !== 'MERCHANT_ID'">
              *系统分账时，打款到该openid用户的推广钱包余额，可到小程序页面进行提现
            </div>
          </el-form-item>

          <el-form-item label="商户全称" prop="name" v-if="form.pay_method === 'MERCHANT_ID'">
            <el-input v-model="form.name" placeholder="请输入商户全称" :disabled="!!form.id" />
          </el-form-item>

          <el-form-item label="打款openid" prop="openid" v-if="form.pay_method === 'MERCHANT_ID'">
            <el-input v-model="form.openid" placeholder="请输入打款openid" />
            <div style="font-size:12px;color:red;">
              *系统分账时，打款到该openid用户的推广钱包余额，可到小程序页面进行提现
            </div>
          </el-form-item>
        </template>

        <template v-if="form.ledger_type === 2">
          <el-form-item label="易票联商户" prop="merchant_id">
            <el-select v-model="form.merchant_id" placeholder="请选择易票联商户" filterable style="width:100%" clearable :disabled="!!form.id">
              <el-option :key="0" label="无" :value="0"></el-option>

              <el-option v-for="item in merchants" :key="item.id" :label="item.merchant_name" :value="item.id"></el-option>
            </el-select>

            <div style="font-size:12px;color:red;">
              *请选择分账方所申请的易票联商户
            </div>
          </el-form-item>
        </template>

<!--        <el-form-item label="特殊分账商家" prop="name" style="width: 90%">-->
<!--          <el-button-->
<!--            icon="el-icon-plus"-->
<!--            size="small"-->
<!--            plain-->
<!--            @click="addShop"-->
<!--          ></el-button>-->
<!--          <div v-for="(item, index) in form.shops" :key="index">-->
<!--            <el-select-->
<!--              v-model="item.shop_id"-->
<!--              @visible-change="getShopList"-->
<!--              :loading="loading"-->
<!--              style="width: 30%; margin-right: 5px; margin-top: 5px"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="shop in shopList"-->
<!--                :key="shop.id"-->
<!--                :label="shop.shop_name"-->
<!--                :value="shop.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-input-->
<!--              v-model="item.money"-->
<!--              placeholder="请输入金额"-->
<!--              style="width: 25%"-->
<!--            />-->
<!--            <span>元</span>+-->
<!--            <el-input-->
<!--              v-model="item.proportion"-->
<!--              placeholder="请输入比例"-->
<!--              style="width: 25%"-->
<!--            />-->
<!--            <span>%</span>-->
<!--            <el-button-->
<!--              icon="el-icon-delete"-->
<!--              size="small"-->
<!--              plain-->
<!--              @click="delShop(index)"-->
<!--            ></el-button>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否结余再分账" prop="is_balance_strike">
          <el-switch v-model="form.is_balance_strike" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>

        <el-form-item label="优先级" prop="weight">
          <el-input-number v-model="form.weight" :min="1" :precision="0"></el-input-number>

          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*优先级越小越优先结算</div>
            <div>*每个店铺的【配送账户】应该排在第一位</div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="支付方式修改" :visible.sync="dialogVisible1" width="30%">
      <el-radio-group v-model="pay_method.pay_method">
        <el-radio label="weixin">微信支付</el-radio>
        <el-radio label="ccb">建行支付</el-radio>
        <el-radio label="sp">服务商支付</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleStore">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      list: null,
      total: null,
      loading: false,
      btnLoading: false,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        ledger_type: 1,
        role_name: "",
        is_use: "",
        is_balance_strike: "",
      },
      shopList: [],
      dialogVisible: false,
      dialogVisible1: false,
      form: {
        id: null,
        school_id: "",
        ledger_type: 1,
        role_name: "",
        money: 0,
        proportion: 0,
        pay_method: "MERCHANT_ID",
        account: "",
        name: "",
        merchant_id: 0,
        shops: [],
        is_balance_strike: 0,
        weight: 1,
        openid: "",
      },
      rules: {
        ledger_type: [{ required: true, message: '分账类型不能为空', trigger: 'change' }],
        role_name: [{ required: true, message: '分账方名称不能为空', trigger: 'change' }],
        money: [{ required: true, message: '金额不能为空', trigger: 'change' }],
        proportion: [{ required: true, message: '比例不能为空', trigger: 'change' }],
        pay_method: [{ required: true, message: '收款方式不能为空', trigger: 'change' }],
        is_balance_strike: [{ required: true, message: '是否结余再分账不能为空', trigger: 'change' }],
        weight: [{ required: true, message: '优先级不能为空', trigger: 'change' }],
      },
      pay_method: {
        id: "",
        pay_method: "",
      },
      inputVisible: false,
      inputValue: "",
      // 商户列表
      merchants: [],
    };
  },
  created() {
    this.getList();
    this.getMerchantList();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.form.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: null,
          school_id: "",
          ledger_type: 1,
          role_name: "",
          money: 0,
          proportion: 0,
          pay_method: "MERCHANT_ID",
          account: "",
          name: "",
          merchant_id: 0,
          shops: [],
          is_balance_strike: 0,
          weight: 1,
          openid: "",
        };
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getShopList(e) {
      this.loading = true;
      if (e) {
        const shop_ids = [];
        this.form.shops.forEach((element) => {
          // console.log(element);
          if (element.shop_id) {
            shop_ids.push(element.shop_id);
          }
        });
        request({
          url: "/api/backend/school/shopList",
          method: "get",
          params: {
            shop_ids: shop_ids,
            school_id: this.form.school_id,
          },
        }).then((response) => {
          this.shopList = response.data;
          this.loading = false;
        });
      }
    },
    // 商户列表
    getMerchantList() {
      request({
        url: "/api/backend/merchant/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          is_on: 1,
        }
      }).then(response => {
        this.merchants = response.data.data;
      });
    },
    choosePosition(res) {
      console.log(res);
      this.form.lng = res.position.lng;
      this.form.lat = res.position.lat;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleClose(tag) {
      this.form.contact.splice(this.form.contact.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.contact.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleCreate() {
      this.form.ledger_type = this.listQuery.ledger_type;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs['form'].clearValidate() //清除校验结果
      });
    },
    async handleEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.getLedgerRoleDetail(item.id);
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs['form'].clearValidate() //清除校验结果
      });
    },
    getLedgerRoleDetail(id) {
      request({
        url: "/api/backend/school/ledgerRoleDetail",
        method: "get",
        params: { id: id },
      }).then((response) => {
        this.form = response.data;
        this.form.shops = this.form.shops == null ? [] : this.form.shops;
        console.log(this.form);
        request({
          url: "/api/backend/school/shopList",
          method: "get",
          params: {
            school_id: this.form.school_id,
          },
        }).then((response) => {
          this.shopList = response.data;
        });
      });
    },
    async handleDel(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.$confirm("确定要删除该分账角色吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/ledgerRoleDel",
          method: "get",
          params: {
            id: item.id,
          },
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
    async handleUse(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.listLoading = true;
      request({
        url: "/api/backend/school/use",
        method: "get",
        params: {
          id: item.id,
        },
      }).then(() => {
        this.getList();
        this.$store.dispatch("GetSchools");
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
    saveData() {
      // let b = true;
      // this.form.shops.forEach((element) => {
      //   if (!element.shop_id) {
      //     this.$message({
      //       type: "warning",
      //       message: "请输入商家",
      //     });
      //     b = false;
      //     return
      //   }
      //   if (element.money==null) {
      //     this.$message({
      //       type: "warning",
      //       message: "请输入金额",
      //     });
      //     b = false;
      //     return
      //   }
      //   if (element.proportion==null) {
      //     this.$message({
      //       type: "warning",
      //       message: "请输入比例",
      //     });
      //     b = false;
      //     return
      //   }
      // });
      // if (b == false) {
      //   return;
      // }

      if (this.form.ledger_type === 1) {
        if (!this.form.account) {
          this.$message({
            type: "warning",
            message: "请输入收款账号",
          });
          return;
        }

        if (this.form.pay_method === "MERCHANT_ID" && !this.form.name) {
          this.$message({
            type: "warning",
            message: "请输入商户全称",
          });
          return;
        }

        if (this.form.pay_method === "MERCHANT_ID" && !this.form.openid) {
          this.$message({
            type: "warning",
            message: "请输入打款openid",
          });
          return;
        }
      } else if (this.form.ledger_type === 2) {
        if (this.form.pay_method !== "MERCHANT_ID") {
          this.$message({
            type: "warning",
            message: "易票联分账的收款方式只能是商户号",
          });
          return;
        }

        if (!this.form.merchant_id) {
          this.$message({
            type: "warning",
            message: "请选择易票联商户",
          });
          return;
        }
      }

      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.form.school_id = this.school_id;
          request({
            url: "/api/backend/school/ledgerRoleStore",
            method: "post",
            data: this.form,
          }).then(() => {
            this.btnLoading = false;
            this.dialogVisible = false;
            this.getList();
            this.$store.dispatch("GetSchools");
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }).finally(() => {
            this.btnLoading = false;
          });
        }
      })
    },
    handleToPhones(item) {
      this.$router.push(`/school/ledgerRolePhone?ledger_role_id=${item.id}`);
    },
    revenueLog(item) {
      this.$router.push(`/school/revenueLog?ledger_role_id=${item.id}`);
    },
    show(item) {
      this.pay_method.id = item.id;
      this.pay_method.pay_method = item.pay_method;
      this.dialogVisible1 = true;
    },
    addShop() {
      let obj = {
        shop_id: null,
        money: null,
        proportion: null,
      };
      this.form.shops.push(obj);
    },
    delShop(idx) {
      this.form.shops.splice(idx, 1);
    },
    handleStore() {
      this.$confirm("确定修改学校的支付方式吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/payMethodStore",
          method: "get",
          params: this.pay_method,
        }).then(() => {
          this.dialogVisible1 = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
</style>
